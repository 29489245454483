<template>
    <page
        id="team-dashboard"
        :title="$root.translate('Team Dashboard')"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <dashboard-wrapper
            v-if="edited_record"
            :record="edited_record"
            entity="team"
        >
            <section v-if="edited_record" class="page-inner-container text-center">
                <div class="page-inner-container-content">
                    <h5>{{ $root.translate("Where My Team has Walked To") }}</h5>
                    <milestones
                        :kilometers="total_distance / 1000"
                    />
                </div>
            </section>
        </dashboard-wrapper>
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page } from "@/nibnut/mixins"
import { addl_profile_utilities, is_avatar_owner, calculator_utilities } from "@/custom/mixins"

import { DashboardWrapper, Milestones } from "@/custom/components"

export default {
    mixins: [is_record_editor_page, addl_profile_utilities, is_avatar_owner, calculator_utilities],
    components: {
        DashboardWrapper,
        Milestones
    },
    watch: {
        walker_uuid: "reload"
    },
    methods: {
        grab_record_id () {
            this.record_id = this.profile.team_uuid
        }
    },
    computed: {
        can_reload () {
            return this.walker_uuid
        },
        cleanup_before_reload () {
            return false
        },
        reload_query () {
            return { relation_ids: this.relation_ids, fields: ["fieldset::dashboard"] }
        },
        total_distance () {
            if(!this.edited_record) return 0
            return this.standardized_distance(this.edited_record.computed_total_distance)
        }
    },
    data () {
        return {
            entity: "team",
            entityName: "Team"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.page {
    & > .page-content#team-dashboard {
        & > .page-body {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            section:last-child {
                margin-bottom: 0;
            }
            .page-outer-container {
                margin: 0;
                max-width: none;
                .page-inner-container {
                    width: 100%;
                    max-width: none;
                    & > .page-inner-container-content {
                        padding: 0 1rem;
                        max-width: 680px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    &.walker-stats {
                        & > .page-inner-container-content {
                            text-align: center;

                            .columns {
                                justify-content: center;
                                .column {
                                    flex: 0 0 6rem;
                                    @media (max-width: $size-sm) {
                                        margin-bottom: 0.8rem;
                                    }
                                }
                            }
                            h6 {
                                font-family: $body-font-family;
                            }
                        }
                    }
                    &.savings {
                        background-color: $gray-color-light;
                        & > .page-inner-container-content {
                            position: relative;
                            padding-top: $unit-10;
                            padding-bottom: ($unit-10 * 2);
                            text-align: center;
                            font-family: "Hop";
                            font-size: 1.2rem;
                            font-weight: normal;
                            line-height: 1rem;

                            &:before {
                                content: " ";
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                height: 100px;
                                width: 140px;
                                background: $gray-color-light no-repeat bottom left url('~@/assets/img/Left_trees_desktop.png');
                                z-index: 0;
                                @media (max-width: $size-sm) {
                                    height: 80px;
                                    width: 54px;
                                    background: $gray-color-light no-repeat bottom left url('~@/assets/img/Left_trees.png');
                                }
                            }
                            &:after {
                                content: " ";
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                height: 114px;
                                width: 198px;
                                background: $gray-color-light no-repeat bottom right url('~@/assets/img/Right_trees_desktop.png');
                                z-index: 0;
                                @media (max-width: $size-sm) {
                                    height: 122px;
                                    width: 114px;
                                    background: $gray-color-light no-repeat bottom right url('~@/assets/img/Right_trees.png');
                                }
                            }
                            h5 {
                                font-size: 1.2rem;
                                z-index: 1;
                            }
                            h4 {
                                z-index: 1;
                                & > .text-large {
                                    font-size: 2.4rem;
                                }
                            }
                        }
                    }
                    &.team-stats {
                        position: relative;
                        background: $brand-orange no-repeat bottom left url('~@/assets/img/Graphic_intro_desktop.png');
                        background-size: 52%;
                        text-align: center;

                        & > .page-inner-container-content {
                            position: relative;
                            padding-top: $unit-10;
                            padding-bottom: ($unit-10 * 2);
                            text-align: center;
                            font-family: "Hop";
                            font-size: 1.2rem;
                            font-weight: normal;
                            line-height: 1rem;
                        }

                        .team-stats-panel {
                            margin-top: -3rem;
                            padding: 1rem;
                            background-color: $brand-yellow;
                            font-size: 1.2rem;
                            font-family: "Hop";
                            font-weight: normal;
                            line-height: 1rem;
                            border-radius: 1rem;

                            .text-small {
                                font-size: 0.7rem;
                                line-height: 1.1em;
                                margin-top: 0.4rem;
                            }
                        }
                        & > img {
                            position: absolute;
                            bottom: 0;
                            right: 2vw;
                            width: 25vw;
                            z-index: $zindex-1;
                        }
                    }
                }
            }
            @media (max-width: $size-sm) {
                .page-outer-container {
                    width: 100%;
                    .page-inner-container {
                        & > .page-inner-container-content {
                            width: calc(100% - 50px);
                            padding: 0;
                            max-width: none;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        &.team-stats {
                            background: $brand-orange no-repeat center bottom url('~@/assets/img/Graphic_intro.png');
                        }
                    }
                }
            }
        }
    }
}
</style>
